import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';
import { notify } from 'react-notify-toast';

const AdminActions = createActions({
    setAction: {},
    hideFooter: {},
    showFooter: {},
    getUsers: { asyncResult: true },
    searchUser: { asyncResult: true },
    filterUsers: { asyncResult: true },
    clearUsers: {},
    updateUser: { asyncResult: true },
    addUser: { asyncResult: true },
    deleteUser: { asyncResult: true },
    getCrediteUser: { asyncResult: true },
    addCrediteUser: { asyncResult: true },
    getAchizitiiUser: { asyncResult: true },
    getConsumUser: { asyncResult: true },
    insertComandaFin: { asyncResult: true },
    getStatusFin: { asyncResult: true },
    insertComandaCip: { asyncResult: true },
    getCipStatus: { asyncResult: true },
    getEmailsThreshold: { asyncResult: true },
    updateMonitorizare: { asyncResult: true },
    getApiStatus: { asyncResult: true },
    verifyApi: { asyncResult: true },
    resetApi: {},
    deleteRaport: { asyncResult: true },
    getFirmeMonitorizate: { asyncResult: true },
    clearFirmeMon: {},
    startStopFirmaMon: { asyncResult: true },
    changeAllMonitorings: { asyncResult: true },
    updateMonOptions: { asyncResult: true },
    deleteMonitorizare: { asyncResult: true },
    getEmails: { asyncResult: true },
    deleteUpdateEmails: { asyncResult: true },
    sendEmailNewUser: { asyncResult: true },
    clearEmails: {},
    getRapAutomatizate: { asyncResult: true },
    getRapAutomatizateUser: { asyncResult: true },
    clearRapAutoUseri: {},
    getRapoarteTypes: { asyncResult: true },
    insertRapAutomatizat: { asyncResult: true },
    insertUserRapAuto: { asyncResult: true },
    deleteUserRapAuto: {asyncResult: true},
    getUserContract: { asyncResult: true },
})

AdminActions.getUsers.listen((pageSize, pageIngex) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_USERS_ADMIN + '?pageSize=' + pageSize + '&pageIndex=' + pageIngex
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getUsers.completed(data);
    }).catch((error) => {
        AdminActions.getUsers.failed(error);
    });
})

AdminActions.searchUser.listen((pageSize, pageIngex, query) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_USER_ADMIN + '?pageSize=' + pageSize + '&pageIndex=' + pageIngex + '&query=' + query
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.searchUser.completed(data);
    }).catch((error) => {
        AdminActions.searchUser.failed(error);
    });
})

AdminActions.filterUsers.listen((filters) => {
    AdminActions.clearUsers()
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.FILTER_USER_ADMIN
    axios.post(
        url, filters,
        {
            headers: headers
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.filterUsers.completed(data);
    }).catch((error) => {
        AdminActions.filterUsers.failed(error);
    });
})

AdminActions.updateUser.listen((userU) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_USER_ADMIN
    axios.put(
        url, userU,
        {
            headers: headers,
        }
    ).then((response) => {
        // const { data } = response;
        AdminActions.updateUser.completed(userU);
        notify.show('Succes', 'success', 3000);
    }).catch((error) => {
        // AdminActions.updateUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.addUser.listen((userU) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CREATE_USER_ADMIN
    axios.post(
        url, userU,
        {
            headers: headers,
        }
    ).then((response) => {
        // const { data } = response;
        // AdminActions.addUser.completed(data.Result);
        AdminActions.setAction()
        notify.show('Succes', 'success', 3000);
    }).catch((error) => {
        // AdminActions.addUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_USER_ADMIN + '/' + userId
    axios.delete(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        // const { data } = response;
        // AdminActions.deleteUser.completed(data.Result);
        AdminActions.setAction()
        notify.show('Success', 'success', 3000);
    }).catch((error) => {
        // AdminActions.deleteUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getCrediteUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_CREDITE_USER_ADMIN + '/' + userId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getCrediteUser.completed(data.Result);
    }).catch((error) => {
        AdminActions.getCrediteUser.failed(error);
        notify.show('Error', error, 3000);
    });
})

AdminActions.addCrediteUser.listen((credite) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.ADD_CREDITE_USER_ADMIN
    axios.post(
        url, credite,
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.addCrediteUser.completed(credite);
    }).catch((error) => {
        notify.show('Error', error, 3000);
    });
})

AdminActions.getAchizitiiUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_ACHIZITII_USER_ADMIN + '/' + userId + '?skipIds=0&takeIds=10'
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getAchizitiiUser.completed(data.Result);
    }).catch((error) => {
        AdminActions.getAchizitiiUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getConsumUser.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_CONSUM_USER_ADMIN + '/' + userId + '?skipIds=0&takeIds=10'
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getConsumUser.completed(data.Result);
    }).catch((error) => {
        AdminActions.getConsumUser.failed(error);
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.insertComandaFin.listen((comanda) => {        //trimite cui-uri la parsat
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.INSERT_COMANDA_FIN
    axios.post(
        url, comanda,
        {
            headers: headers,
        }
    ).then((response) => {
        notify.show('Comanda inserata cu success', 'success', 3000);
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getStatusFin.listen((tip) => {        //trimite cui-uri la parsat
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_FIN_STATUS + '/' + tip
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response
        AdminActions.getStatusFin.completed(data)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.insertComandaCip.listen((comanda) => {        //trimite cui-uri la parsat
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.INSERT_COMANDA_CIP
    axios.post(
        url, comanda,
        {
            headers: headers,
        }
    ).then((response) => {
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getCipStatus.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_CIP_STATUS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getCipStatus.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getEmailsThreshold.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_EMAILS_THRESHOLD
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getEmailsThreshold.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.updateMonitorizare.listen(monitorizareInfo => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_MONITORIZARE
    axios.post(
        url, monitorizareInfo,
        {
            headers: headers,
        }
    ).then((response) => {
        notify.show('Succes', 'success', 3000);
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getApiStatus.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_API_STATUS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getApiStatus.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.verifyApi.listen((requestData) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.VERIFY_API
    axios.post(
        url, requestData,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.verifyApi.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteRaport.listen((raportId, userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.DELETE_RAPORT + '/' + raportId
    axios.delete(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        AdminActions.getConsumUser(userId)
        notify.show('Success', 'success', 3000);
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getFirmeMonitorizate.listen(userId => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_FIRME_MONITORIZATE_ADMIN + '/' + userId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getFirmeMonitorizate.completed(data)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.startStopFirmaMon.listen((userId, type, firmaId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.START_STOP_MON_FIRMA + '/' + type + '/' + firmaId + '/' + userId
    axios.put(
        url, {},
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.getFirmeMonitorizate(userId)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.changeAllMonitorings.listen((userId, action) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CHANGE_ALL_MONITORINGS + '/' + action + '/' + userId
    axios.put(
        url, {},
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.getFirmeMonitorizate(userId)
        notify.show('Success', 'success', 3000);
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.updateMonOptions.listen((monOptions, userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_MONITORIZARE_OPTIONS
    monOptions = { ...monOptions, UserId: userId }
    axios.put(
        url, monOptions,
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.getFirmeMonitorizate(userId)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteMonitorizare.listen((idFirma, userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.DELETE_MONITORIZARE + '/' + idFirma + '/' + userId
    axios.delete(
        url,
        {
            headers: headers,
        }
    ).then(() => {
        notify.show('Success', 'success', 3000);
        AdminActions.clearFirmeMon()
        AdminActions.getFirmeMonitorizate(userId)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getEmails.listen((emailFilters) => {
    AdminActions.clearEmails()
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_EMAILS
    axios.post(
        url, emailFilters,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getEmails.completed(data)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteUpdateEmails.listen((type, ids, emailFilters) => {
    AdminActions.clearEmails()
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.DELETE_UPDATE_EMAILS
    axios.put(
        url, { EmailIds: ids, Type: type },
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.getEmails(emailFilters)
        notify.show('Success', 'success', 3000);
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.sendEmailNewUser.listen((emailId, newEmail) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEND_EMAIL_NEW_USER
    axios.post(
        url, { EmailId: emailId, NewEmail: newEmail },
        {
            headers: headers,
        }
    ).then(() => {
        notify.show('Success', 'success', 3000);
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getRapAutomatizate.listen((rapFilters) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_RAP_AUTOMATIZATE
    axios.post(
        url, rapFilters,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getRapAutomatizate.completed(data)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getRapAutomatizateUser.listen((rapFilters) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_RAP_AUTOMATIZATE_USERI
    axios.post(
        url, rapFilters,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getRapAutomatizateUser.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getRapoarteTypes.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_RAP_TYPES
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getRapoarteTypes.completed(data)
    }).catch((error) => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.insertRapAutomatizat.listen((comanda) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.INSERT_RAP_AUTO_COMANDA
    axios.post(
        url, comanda,
        {
            headers: headers,
        }
    ).then(() => {
        notify.show('Success', 'success', 3000);
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.insertUserRapAuto.listen((userInfo, rapFilters) => {
    AdminActions.clearRapAutoUseri()
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.INSERT_RAP_AUTO_USER
    axios.post(
        url, userInfo,
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.getRapAutomatizateUser(rapFilters)
        notify.show('Success', 'success', 3000);
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.deleteUserRapAuto.listen((userInfo, rapFilters) => {
    AdminActions.clearRapAutoUseri()
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.DELETE_RAP_AUTO_USER
    axios.post(
        url, userInfo,
        {
            headers: headers,
        }
    ).then(() => {
        AdminActions.getRapAutomatizateUser(rapFilters)
        notify.show('Success', 'success', 3000);
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

AdminActions.getUserContract.listen((userId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_USER_CONTRACT + '/' + userId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AdminActions.getUserContract.completed(data)
    }).catch(() => {
        notify.show('Error', 'error', 3000);
    });
})

export default AdminActions